import React, { useEffect, useContext} from "react";
import { useNavigate } from 'react-router-dom'
import { StoreContext } from "../store";
import * as Routes from "../routes"

const Home = () => {
  const { state, dispatch } = useContext(StoreContext);
  const navigate = useNavigate();
  
  
  useEffect(() => {
      if(state.reports && state.reports.length > 0 ){
          navigate(Routes.CLIENT_REPORT_VIEW_LINK(state.reports[0].organisation.slug, state.reports[0].instance.slug))
      }
  }, [state.reports])

  return <>You have no Reports</>;
};

export default Home;