import React, { createContext, useReducer } from "react";
import { RootState } from "../common/types";

const initialState: RootState = {
    user: null,
    reports: []
}

export const StoreContext = createContext<{ state: RootState, dispatch: React.Dispatch<any>}>({ state: initialState, dispatch: () => null });

const StateProvider: React.FC<React.ReactNode> = ({ children }) => {
    
    const [state, dispatch] = useReducer((state: RootState, action: any): RootState => {
        switch (action.type) {
          case "SET_USER":
            return {
              ...state,
              user: action.payload,
            };
          case "SET_REPORTS":
            return {
              ...state,
              reports: action.payload,
            };
          case "CREATE_REPORT":
            return {
              ...state,
              reports: state.reports.concat(action.payload),
            };
          case "UPDATE_REPORT":
            return {
              ...state,
              reports: state.reports.map((report) => { 
                  if(report.id === action.payload.id) { 
                    return action.payload
                  }
                  return report  
                }),
            };
          case "DELETE_REPORT":
            return {
              ...state,
              reports: state.reports.filter((report) => { 
                if (report.id === action.payload.id) {
                    return null;
                }
                return report;  
              }),
            };
          default:
            return { ...state };
        }
    }, initialState)

    
    return(
        <StoreContext.Provider value={{state, dispatch}}>
            { children }
        </StoreContext.Provider>
    )
}

export const useStoreContext = () => {
    const context = React.useContext(StoreContext)
    if(context === undefined){
        throw new Error('context is undefined')
    }

    return context
}

export default StateProvider;