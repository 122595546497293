import React, { useContext, useEffect, useState, useRef } from "react";
import { StoreContext } from "../store";
import { IReportLinks, IInstance, IReport } from "../common/types";
import { useNavigate, useParams } from "react-router-dom";
import * as Routes from "../routes";
import { Editor } from "@tinymce/tinymce-react";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

const AdminSummaryCreate = () => {
  const { state } = useContext(StoreContext);
  const editor1Ref = useRef(null);
  const editor2Ref = useRef(null);
  const editor3Ref = useRef(null);
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const [fetching, setFetching] = useState(false);
  const [summary, setSummary] = useState<SummaryFormBody>({
    name: "",
    overviewContent: "",
    completedTasksContent: "",
    currentFocusContent: ""
  });

  useEffect(() => {
    if (state.user && params.id) {
      setFetching(true);
      const url = `${process.env.REACT_APP_BASE_URL}/v1/report/${params.report}/summary/${params.id}`;
      fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${state.user!.token}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setFetching(false);

          setSummary({
            name: res.name,
            overviewContent: res.overviewContent,
            completedTasksContent: res.completedTasksContent,
            currentFocusContent: res.currentFocusContent
          });
        });
    }
  }, [state.user]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSummary({
      ...summary,
      [event.target.name]: event.target.value,
    });
  };

  const handleEditorChange = (field: string) => {
    console.log(field)
    
    if (field === "overviewContent" && editor1Ref.current) {
      // @ts-expect-error
      setSummary({ ...summary, overviewContent: editor1Ref.current.getContent() });
    }
     if (field === "currentFocusContent" && editor2Ref.current) {
       // @ts-expect-error
       setSummary({...summary, currentFocusContent: editor2Ref.current.getContent(),
       });
     }
      if (field === "completedTasksContent" && editor3Ref.current) {
        // @ts-expect-error
        setSummary({ ...summary, completedTasksContent: editor3Ref.current.getContent()});
      }
  };

  const submit = () => {
    const url = params.id
      ? `${process.env.REACT_APP_BASE_URL}/v1/report/${params.report}/summary/${params.id}`
      : `${process.env.REACT_APP_BASE_URL}/v1/report/${params.report}/summary`;
    const body = JSON.stringify({
      report: {
        ...summary,
      },
    });
    console.log(body);
    fetch(url, {
      method: params.id ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.user!.token}`,
      },
      body: body,
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        setErrors([String(res.status)]);
      })
      .then((res) => {
        navigate(Routes.ADMIN);
      });
  };

  return (
    <>
      {fetching ? (
        <>Loading...</>
      ) : (
        <Paper variant="outlined" sx={{ p: { xs: 2, md: 4 } }}>
          <Stack spacing={2}>
            <Typography variant="h4" component="h1">
              {params.id ? "Update" : "New"} Report
            </Typography>
            <TextField
              fullWidth
              label="title"
              id="name"
              name="name"
              value={summary.name}
              onChange={handleChange}
            />
            <label>Monthly Overview</label>
            <Editor
              // @ts-expect-error
              onInit={(evt, editor) => (editor1Ref.current = editor)}
              value={summary.overviewContent}
              apiKey="2dqjlzhfais6i4d8ltfd45j4viaj62fbalr7re4gd73lzci0"
              init={{
                height: 700,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic |  bullist numlist | blockquote code | link image | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
              onEditorChange={() => handleEditorChange("overviewContent")}
            />

            <label>Current Focus</label>
            <Editor
              // @ts-expect-error
              onInit={(evt, editor) => (editor2Ref.current = editor)}
              value={summary.currentFocusContent}
              apiKey="2dqjlzhfais6i4d8ltfd45j4viaj62fbalr7re4gd73lzci0"
              init={{
                height: 700,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic |  bullist numlist | blockquote code | link image | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
              onEditorChange={() => handleEditorChange("currentFocusContent")}
            />

            <label>Completed Tasks</label>
            <Editor
              // @ts-expect-error
              onInit={(evt, editor) => (editor3Ref.current = editor)}
              value={summary.completedTasksContent}
              apiKey="2dqjlzhfais6i4d8ltfd45j4viaj62fbalr7re4gd73lzci0"
              init={{
                height: 700,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic |  bullist numlist | blockquote code | link image | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
              onEditorChange={() => handleEditorChange("completedTasksContent")}
            />

            <Button onClick={submit} variant="contained" size="large">
              {" "}
              {params.id ? "Update" : "New"} Report
            </Button>
          </Stack>
        </Paper>
      )}
    </>
  );
};

interface SummaryFormBody {
  name: string;
  overviewContent: string;
  completedTasksContent: string;
  currentFocusContent: string;
}

export default AdminSummaryCreate;
