import React, { useEffect, useContext, useState } from "react";
import { BrowserRouter, Route, Routes as Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { StoreContext } from "./store";
import Layout from "./components/layout";
import ClientDashboard from "./pages/ClientDashboard";
import ClientReport from "./pages/ClientReport";
import Admin from "./pages/Admin";
import AdminReportCreate from "./pages/AdminReportCreate";
import AdminSummaryCreate from "./pages/AdminSummaryCreate";
import * as Routes from "./routes";
import Home from "./pages/Home";
import Feedback from "./pages/Feedback";

function App() {
  const { state, dispatch } = useContext(StoreContext);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    try {
      const user = extractAuthCookieInfo();
      dispatch({ type: "SET_USER", payload: user });
    } catch (error: any) {
      console.log(error.message);
      window.location.href = process.env.REACT_APP_LOGIN_URL as string;
    }
  }, []);

  useEffect(() => {
    setFetching(true);
    if (state.user) {
      fetch(`${process.env.REACT_APP_BASE_URL}/v1/report`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${state.user!.token}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setFetching(false);
          dispatch({ type: "SET_REPORTS", payload: res });
        });
    }
  }, [state.user]);

  return (
    <>
      {fetching ? (
        "loading"
      ) : (
        <>
          <BrowserRouter>
            <Layout>
              <Switch>
                <Route
                  path={Routes.CLIENT_DASHBOARD_VIEW}
                  element={<ClientDashboard />}
                />
                <Route
                  path={Routes.CLIENT_REPORT_VIEW}
                  element={<ClientReport />}
                />
                <Route
                  path={Routes.EDIT_REPORT}
                  element={<AdminReportCreate />}
                />
                <Route
                  path={Routes.CREATE_REPORT}
                  element={<AdminReportCreate />}
                />
                <Route
                  path={Routes.EDIT_SUMMARY}
                  element={<AdminSummaryCreate />}
                />
                <Route
                  path={Routes.CREATE_SUMMARY}
                  element={<AdminSummaryCreate />}
                />
                <Route path={Routes.ADMIN} element={<Admin />} />
                <Route path={Routes.HOME} element={<Home />} />
                <Route path={Routes.FEEDBACK} element={<Feedback />} />
              </Switch>
            </Layout>
          </BrowserRouter>
        </>
      )}
    </>
  );
}

const extractAuthCookieInfo = () => {
  const cookie = document.cookie
    .split(";")
    .find((row) =>
      row.trim().startsWith(process.env.REACT_APP_COOKIE_NAME as string)
    );

  if (cookie) {
    const [cookieKey, cookieValue] = cookie.split("=");
    const token: any = jwt_decode(cookieValue);
    let dateNow = new Date();

    if (token.exp * 1000 > dateNow.getTime()) {
      return {
        ...token.user,
        token: cookieValue,
      };
    } else {
      throw new Error(`${cookieKey} token is expired`);
    }
  } else {
    throw new Error("no auth cookie");
  }
};

export default App;
