import { useState, useEffect, useContext } from "react";
import {
  Container,
  Paper,
  Typography,
  Button,
  Stack,
  IconButton,
  Tooltip,
  Box,
  Modal,
  CircularProgress,
  Divider,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import ReplyIcon from "@mui/icons-material/Reply";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { StoreContext } from "../store";
import { useParams } from "react-router-dom";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  px: 4,
  pb: 4,
  pt: 6,
};


const Feedback = () => {
  const { state } = useContext(StoreContext);
  const [feedbacks, setFeedbacks] = useState<any[]>([]);
  const [feedbackInput, setFeedbackInput] = useState("");
  const [commentInput, setCommentInput] = useState("");
  const [commentId, setCommentId] = useState<number | undefined>();
  const [success, setSuccess] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = (commentId: number) => {
    setCommentId(commentId);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const params = useParams();

  useEffect(() => {
    setFetching(true);
    if (state.user) {
      fetch(`${process.env.REACT_APP_BASE_URL}/v1/feedback`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${state.user!.token}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          const filteredFeedbacks = res.filter((feedback:any) => {
            if (feedback.organisation.slug === params.organisation) return feedback
          })
        
          setFetching(false);
          setFeedbacks(filteredFeedbacks);
        });
    }
  }, [state.user]);

  const submit = () => {
    setFetching(true);
    const body = JSON.stringify({
      feedback: {
        feedback: feedbackInput,
      },
    });

    fetch(`${process.env.REACT_APP_BASE_URL}/v1/feedback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.user!.token}`,
      },
      body: body,
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then((res) => {
        const newFeedback = feedbacks.concat(res);
        setFeedbacks(newFeedback);
        setSuccess(true);
        setFetching(false);
      });
  };

  const submitComment = (id: number) => {
    const body = JSON.stringify({
      comment: {
        comment: commentInput,
        feedbackId: id,
      },
    });

    fetch(`${process.env.REACT_APP_BASE_URL}/v1/feedback/${id}/comment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.user!.token}`,
      },
      body: body,
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then((res) => {
        const feedbackParent = feedbacks.find((feedback) => {
          if (feedback.id == res.feedbackId) return feedback;
        });
        const feedbackComments = feedbackParent.comments.concat(res);
        feedbackParent.comments = feedbackComments;
        const feedbackMap = feedbacks.map((feedback) => {
          if (feedback.id === feedbackParent.id) {
            return feedbackParent;
          }
          return feedback;
        });
        setFeedbacks(feedbackMap);
        setOpen(false);
        setCommentInput("");
      });
  };

  const deleteFeedback = (feedbackId: number) => {
    const deleteRoute =
      `${process.env.REACT_APP_BASE_URL}/v1/feedback/${feedbackId}` as string;
    fetch(deleteRoute, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.user!.token}`,
      },
    }).then((res) => {
      res.json();
      const newFeedbackArray = feedbacks.filter(
        (feedback) => feedback.id != feedbackId
      );
      setFeedbacks(newFeedbackArray);
    });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            onClick={handleClose}
            size="small"
            aria-label="Delete"
            sx={{ position: "absolute", right: 10, top: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <Stack gap={2}>
            <FormControl>
              <InputLabel htmlFor="component-outlined">Message</InputLabel>
              <OutlinedInput
                id="component-outlined"
                value={commentInput}
                onChange={(e) => setCommentInput(e.target.value)}
                label="Message"
                multiline
                rows={12}
              />
            </FormControl>
            <Button
              onClick={() => submitComment(commentId!)}
              sx={{ maxWidth: 200 }}
              variant="contained"
            >
              Reply to Feedback
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Container>

        <Stack gap={2}>
          {state.user?.role === "USER" ? (
            <Paper sx={{ p: 4 }}>
              <Stack>
                <Typography
                  component="h1"
                  variant="h5"
                  sx={{ mb: 1, fontWeight: 500 }}
                >
                  Leave Feedback
                </Typography>
                {success ? (
                  "Thankyou for leaving feedback"
                ) : (
                  <>
                    <Typography component="p" sx={{ mb: 3 }}>
                      We love to hear how we are doing and how we can continue
                      to improve our service.
                    </Typography>
                    <FormControl>
                      <InputLabel htmlFor="component-outlined">
                        Message
                      </InputLabel>
                      <OutlinedInput
                        id="component-outlined"
                        value={feedbackInput}
                        onChange={(e) => setFeedbackInput(e.target.value)}
                        label="Message"
                        multiline
                        rows={4}
                      />
                    </FormControl>
                    <Button
                      onClick={submit}
                      sx={{ mt: 2, maxWidth: 200 }}
                      variant="contained"
                      disabled={fetching}
                    >
                      {fetching ? (
                        <CircularProgress size="26px" />
                      ) : (
                        "Submit Feedback"
                      )}
                    </Button>
                  </>
                )}
              </Stack>
            </Paper>
          ) : null}
          {feedbacks
            ? [...feedbacks].reverse().map((feedback: any) => {
                return (
                  <>
                    <Paper sx={{ p: 4 }}>
                      <Stack gap={2}>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="start"
                        >
                          <div>
                            <p>
                              {feedback.author.name}{" "}
                              {new Date(feedback.createdAt).toLocaleString(
                                "en-GB"
                              )}
                            </p>
                            <p>{feedback.feedback}</p>
                          </div>
                          <Stack direction="row" spacing={1}>
                            <Tooltip title="Reply to feedback">
                              <IconButton
                                onClick={() => handleOpen(feedback.id)}
                                size="small"
                                aria-label="Reply"
                              >
                                <ReplyIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete Feedback">
                              <IconButton
                                onClick={() => deleteFeedback(feedback.id)}
                                size="small"
                                aria-label="Delete"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </Stack>

                        {feedback.comments
                          ? feedback.comments.map((comment: any) => (
                              <>
                                <Divider light />
                                <p>{comment.comment}</p>
                              </>
                            ))
                          : null}
                      </Stack>
                    </Paper>
                  </>
                );
              })
            : null}
        </Stack>
      </Container>
    </>
  );
};

export default Feedback;
