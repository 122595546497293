import React, { useEffect, useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { IMonthlyReportSummary } from "../common/types";
import { StoreContext } from "../store";
import * as Routes from "../routes";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
const ClientReport = () => {
  const { state, dispatch } = useContext(StoreContext);
  const [reportSummary, setReportSummary] =
    useState<IMonthlyReportSummary | null>(null);
  const [reportLinks, setReportLinks] = useState<string[]>([]);
  const params = useParams();

  useEffect(() => {
    const currentReport = state.reports.find((report) => {
      if (report.instance.slug === params.instance) {
        return report;
      }
    });

    if (currentReport) {
      const report = currentReport;
      if (report.monthlySummaries && report.monthlySummaries.length > 0) {
        setReportSummary(report.monthlySummaries[0]);
      }
      else {
        setReportSummary(null)
      }
    }
  }, [state.reports, params.instance]);

  return (
    <>
      {reportSummary ? (
        <Paper variant="outlined" sx={{ p: { xs: 2, md: 4 } }}>
          <Typography variant="h4" component="h1">
            {reportSummary.name}
          </Typography>
          <Typography variant="h5" component="h2">
            Monthly Overview
          </Typography>

          <div
            dangerouslySetInnerHTML={{ __html: reportSummary.overviewContent }}
          />
          {reportSummary.currentFocusContent ? (
            <>
              <Typography variant="h5" component="h2">
                Current Focus
              </Typography>

              <div
                dangerouslySetInnerHTML={{
                  __html: reportSummary.currentFocusContent,
                }}
              />
            </>
          ) : null}

          {reportSummary.completedTasksContent ? (
            <>
              <Typography variant="h5" component="h2">
                Completed Tasks
              </Typography>

              <div
                dangerouslySetInnerHTML={{
                  __html: reportSummary.completedTasksContent,
                }}
              />
            </>
          ) : null}
        </Paper>
      ) : (
        <Typography variant="body1" component="h1" align="center">
          Sorry there is no new report summary
        </Typography>
      )}
    </>
  );
};

export default ClientReport;
