import React, { useEffect, useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { IMonthlyReportSummary, IReportLinks } from "../common/types";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { StoreContext } from "../store";
import * as Routes from "../routes";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
const ClientDashboard = () => {
  const { state, dispatch } = useContext(StoreContext);
  const [reportLinks, setReportLinks] = useState<IReportLinks[]>([]);
  const params = useParams();

  const previousPage = Number(params.page) - 1;
  const nextPage = Number(params.page) + 1;

  useEffect(() => {
    const currentReport = state.reports.filter((report) => {
      if (report.instance.slug === params.instance) {
        return report;
      }
      return null;
    });
    if (currentReport && currentReport.length > 0) {
      const report = currentReport[0];
      if (report.reportLinks.length && report.reportLinks.length > 0) {
        setReportLinks(report.reportLinks);
      }
    }
  }, [state.reports]);

  if (reportLinks && reportLinks.length) {
    return (
      <>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ marginBottom: 2 }}
        >
          <Typography variant="h4" component="h1">
            {reportLinks[Number(params.page) - 1].title}
          </Typography>
          <Stack
            direction="row"
            alignItems="end"
            justifyContent="flex-end"
            spacing={1}
            divider={<Divider orientation="vertical" flexItem />}
          >
            {previousPage >= 1 ? (
              <Button
                variant="text"
                component={Link}
                to={Routes.CLIENT_DASHBOARD_VIEW_LINK(
                  params.organisation!,
                  params.instance!,
                  String(previousPage)
                )}
                startIcon={
                  <ChevronLeftIcon
                    color="primary"
                    aria-label="Previous Report"
                  />
                }
              >
                {" "}
                <Typography
                  variant="button"
                  component="p"
                  sx={{ display: { xs: "none", md: "inline" } }}
                >
                  {previousPage}- {reportLinks[previousPage - 1].title}
                </Typography>
              </Button>
            ) : null}

            {nextPage <= reportLinks.length ? (
              <Button
                variant="text"
                component={Link}
                to={Routes.CLIENT_DASHBOARD_VIEW_LINK(
                  params.organisation!,
                  params.instance!,
                  String(nextPage)
                )}
                endIcon={
                  <ChevronRightIcon
                    color="primary"
                    aria-label="Previous Report"
                  />
                }
              >
                {" "}
                <Typography
                  variant="button"
                  component="p"
                  sx={{ display: { xs: "none", md: "inline" } }}
                >
                  {reportLinks[nextPage - 1].title} - {nextPage}
                </Typography>
              </Button>
            ) : null}
          </Stack>
        </Stack>
        <iframe
          width="100%"
          height="700"
          src={reportLinks[Number(params.page) - 1].link}
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen
        ></iframe>
      </>
    );
  }

  return <>Invalid Report</>;
};

export default ClientDashboard;
