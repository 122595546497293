import React, { useEffect, useContext, useState } from "react";
import "../assets/table.css";
import { IMonthlyReportSummary, IReportLinks } from "../common/types";
import { StoreContext } from "../store";
import * as Routes from "../routes";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import MonthlySummaryCell from "../components/MonthlySummaryCell";
const Admin = () => {


  const { state } = useContext(StoreContext);



  useEffect(() => {
    const dunno = state.reports.map((report) => {
      if (report.monthlySummaries.length) {
        const updateThing = report.monthlySummaries.reduce(
          (prev, curr): any => {
            return new Date(curr.createdAt) > new Date(prev.createdAt)
              ? curr
              : prev;
          }
        );
        console.log(updateThing);
      }
    });
  }, [state.reports]);

  const getLatestSummary = (
    summaries: IMonthlyReportSummary[],
    reportID: number
  ): any => {
    if (summaries.length > 1) {
      const latestSummary = summaries.reduce(
        (prev, curr): IMonthlyReportSummary => {
          return new Date(curr.createdAt) > new Date(prev.createdAt)
            ? curr
            : prev;
        }
      );

      return (
        <LastUpdatedTableCell
          createdAt={latestSummary.createdAt}
          summaryID={latestSummary.id}
          reportID={reportID}
        />
      );
    }
    if (summaries.length > 0) {
      return (
        <LastUpdatedTableCell
          createdAt={summaries[0].createdAt}
          summaryID={summaries[0].id}
          reportID={reportID}
        />
      );
    }
    return <span>N/A</span>;
  };

  return (
    <Paper variant="outlined" sx={{ pt: 1, pb: 3 }}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        justifyContent="space-between "
        sx={{ padding: 3 }}
      >
        <Typography variant="h4" component="h1">
          Admin
        </Typography>
        <Button variant="contained" component={Link} to={Routes.CREATE_REPORT}>
          Create Report{" "}
        </Button>
      </Stack>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ paddingLeft: 3 }}>Instance</TableCell>
            <TableCell>Organisation</TableCell>
            <TableCell className="hide-cell">Last Monthly Summary</TableCell>
            <TableCell className="hide-cell">No. of Monthly Summary</TableCell>
            <TableCell sx={{ paddingRight: 3 }} align="right">
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {state.reports.map((report) => {
            return (
              <TableRow key={report.id}>
                <TableCell sx={{ paddingLeft: 3 }}>
                  {report.instance.name}
                </TableCell>
                <TableCell>{report.organisation.name}</TableCell>
                <TableCell className="hide-cell">
                

                  <MonthlySummaryCell reportID={report.id} summaries={report.monthlySummaries}/>
             
                </TableCell>
                <TableCell className="hide-cell">
                  {report.monthlySummaries.length}
                </TableCell>
                <TableCell sx={{ paddingRight: 3 }}>
                  <Stack direction="row" spacing={1} justifyContent="flex-end">
                    <IconButton
                      aria-label="edit"
                      size="small"
                      component={Link}
                      to={Routes.REPORT_EDIT_LINK(String(report.id))}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="new"
                      size="small"
                      component={Link}
                      to={Routes.SUMMARY_CREATE_LINK(String(report.id))}
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
};

const LastUpdatedTableCell = (props: TableCellProps) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <span>{props.createdAt}</span>
      <IconButton
        aria-label="edit"
        size="small"
        component={Link}
        to={Routes.SUMMARY_EDIT_LINK(
          String(props.reportID),
          String(props.summaryID)
        )}
      >
        <EditIcon fontSize="small" />
      </IconButton>
    </Stack>
  );
};

interface TableCellProps {
  createdAt: string;
  reportID: number;
  summaryID: number;
}

export default Admin;
