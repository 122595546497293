import React, { useState, useEffect } from "react";
import { IMonthlyReportSummary } from "../common/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import * as Routes from "../routes";


const MonthlySummaryCell = (props: MonthlySummaryCellProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [selectedSummary, setSelectedSummary] =
    useState<IMonthlyReportSummary | null>(null);

  useEffect(() => {
    if (props.summaries.length === 1) {
      setSelectedSummary(props.summaries[0]);
    } else if (props.summaries.length > 1) {
      const latestSummary = props.summaries.reduce(
        (prev, curr): IMonthlyReportSummary => {
          return new Date(curr.createdAt) > new Date(prev.createdAt)
            ? curr
            : prev;
        }
      );
      setSelectedSummary(latestSummary);
    }
  }, [props.summaries]);

  return (
    <>
      {selectedSummary ? (
        <Stack direction="row" spacing={1} alignItems="center">
          <div>
            <Typography>{selectedSummary.name}</Typography>
            <Typography variant="caption">
              {selectedSummary.createdAt.substring(0, 10)}
            </Typography>
          </div>
          <Stack direction="row" spacing={".5px"} alignItems="center">
            <IconButton
              aria-label="edit"
              size="small"
              component={Link}
              to={Routes.SUMMARY_EDIT_LINK(
                String(props.reportID),
                String(selectedSummary.id)
              )}
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton aria-label="edit" size="small" onClick={handleClick}>
              <ExpandMoreIcon fontSize="small" />
            </IconButton>
          </Stack>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <List>
              {props.summaries
                .filter((summary) => summary.id != selectedSummary.id)
                .map((summary) => {
                  return (
                    <ListItemButton
                      onClick={() => {
                        setSelectedSummary(summary);
                        handleClose();
                      }}
                      key={summary.id}
                      alignItems="flex-start"
                      style={{ flexDirection: "column" }}
                    >
                      <Typography> {summary.name}</Typography>
                      <Typography variant="caption">
                        {summary.createdAt.substring(0, 10)}
                      </Typography>
                    </ListItemButton>
                  );
                })}
            </List>
          </Popover>
        </Stack>
      ) : (
        <span>N/A</span>
      )}
    </>
  );
};

interface MonthlySummaryCellProps {
  summaries: IMonthlyReportSummary[];
  reportID: number;
}

export default MonthlySummaryCell;
